/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

@media(min-width: 1px) and (max-width: 660px){
	h1{font-size: 26px;}
	h2{font-size: 22px;}
	h3{font-size: 22px;}

	.border-white{left: 20px; right: 20px; top: 60px;}
	.link-line{font-size: 12px;}
	.link-line:before{top: 10px;}
	.title-content{margin-bottom: 20px;}
	.title-content h1{margin-bottom: 5px;}
	.title-content h4{font-size: 18px; line-height: 24px;}
	.title-content h4.caps-detail{font-size: 16px; line-height: 24px; margin-top: 10px; margin-bottom: 10px;}
	.wrapper{padding: 0 20px;}
	.banner{height: 350px;}
	.shadow_top, .slidehome .list-slide .bg-shadow-top{height: 180px;}
	.icwp.ic-quotes{background-size: cover; width: 28px; height: 24px;}
	.lineh{margin: 10px 0;}

	.btn-oval{height: 35px; line-height: 35px; font-size: 12px;}
	.btn-oval.btn-loadmore{font-size: 12px;}

	header{padding: 0 20px; min-height: 60px;}
	header .logo img, header .logo-float img{width: 90px;}
	header .nav_right{min-height: 100vh;}
	header .nav_right .nav_header{padding-bottom: 30px;}
	header .nav_right .nav_header li{padding: 5px 0; line-height: 24px;}
	header .nav_right .nav_header li a{font-size: 18px;}
	header .btn-burg-menu{right: 20px;}

	.slidehome .list-slide{min-height: 480px;}
	.slidehome .list-slide figure img{height: 480px;}
	.slidehome .list-slide .desc{font-size: 20px; line-height: 28px; padding: 0 30px; @include boxSizing(border-box);}
	.slidehome .list-slide .desc .logo-lg{margin-bottom: 10px;}
	.slidehome .list-slide .desc .logo-lg img{width: 220px;}

	.sec-content{padding-top: 10px;}
	.sec-content .desc-sechome{left: 20px; padding-left: 15px; max-width: 90%;}
	.sec-content .desc-sechome h2{line-height: 26px; margin-bottom: 10px;}
	.sec-content .desc-sechome h2 .line-v{left: -15px; width: 3px;}
	.sec-content .desc-sechome .quotes{font-size: 14px; line-height: 18px;}

	.sec-contact .desc-sechome{left: 20px;}
	.sec-contact .flwdev.flw-02{display: none;}
	.sec-contact > figure{top: 0; right: 0;}
	.sec-contact > figure img{height: 280px;}

	.sec-aboutus .rings{display: none;}
	.sec-aboutus > figure{left: 22%;}
	.sec-aboutus > figure img{height: 300px;}

	.sec-wedding-service{padding-bottom: 40px; min-height: 0;}
	.sec-wedding-service .bg-weddingservice{background: #fff;}
	.sec-wedding-service .content-wedding{padding-top: 20px;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding{width: 100%; margin: 0; padding: 20px 0;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding figure{text-align: center;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding figure img{max-width: 280px;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding p{margin-bottom: 15px;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding .title-thumb-wedding{padding-left: 35px; margin: 20px 0}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding .title-thumb-wedding h2{font-size: 22px; line-height: 26px; height: auto;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding .title-thumb-wedding .line-v{bottom: 0; width: 3px; left: 20px; height: 90px;}

	.sec-wedding-service .content-wedding .box-weddingserviceone{padding: 20px 20px 40px;}
	.sec-wedding-service .content-wedding .box-weddingserviceone .title-thumb-wedding{margin-top: 0;}
	.sec-wedding-service .content-wedding .box-weddingserviceone .title-thumb-wedding h2{font-size: 26px; line-height: 26px; height: auto;}
	.sec-wedding-service .content-wedding .box-weddingserviceone .title-thumb-wedding .line-v{bottom: 0; width: 3px; left: -20px; height: 90px;}

	.sec-ourvenue .inner-ourvenue .slide-venue .slidervenue .list-slide{height: auto;}
	.sec-ourvenue .inner-ourvenue .slide-venue .slidervenue .list-slide figure{height: 208px;}
	.sec-ourvenue .inner-ourvenue .slide-venue .slidervenue .list-slide figure img{height: 208px;}
	.sec-ourvenue .inner-ourvenue .desc-venue{padding: 27px 20px; font-size: 14px;}
	.sec-ourvenue .inner-ourvenue .desc-venue h2{margin-bottom: 10px; padding-left: 20px;}
	.sec-ourvenue .inner-ourvenue .desc-venue h2 .line-v{width: 3px; left: 0; height: 100px;}

	.sec-maps .mapshome{height: 230px;}

	.our-address .box-ouraddress .inner-ouraddress{padding-top: 0; padding-bottom: 150px;}
	.our-address .box-ouraddress .inner-ouraddress .left{width: 100%; padding: 20px 30px; margin: 0 auto; width: 90%; float: none; display: block; position: relative; background: #fff; top: -35px; font-size: 14px;}
	.our-address .box-ouraddress .inner-ouraddress .left h1{line-height: 30px; margin-bottom: 10px;}
	.our-address .box-ouraddress .inner-ouraddress .left h1 .line-v{width: 3px; height: 70px; left: -14px;}
	.our-address .box-ouraddress .inner-ouraddress .left h4{font-size: 18px; margin-bottom: 10px;}
	.our-address .box-ouraddress .inner-ouraddress .right{width: 100%; padding: 0 20px;}
	.our-address .box-ouraddress .inner-ouraddress .right .career-icon{margin-bottom: 15px;}
	.our-address .box-ouraddress .inner-ouraddress .right .career-icon img{width: 60px;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome{width: 100%; font-size: 14px;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome h3{left: 80px; top: -60px; line-height: 26px;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome .box-sosmed-lg{bottom: 35px;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome .box-sosmed-lg a{width: 17%;}

	.aboutbox{padding-bottom: 50px;}
	.aboutbox h1{margin-bottom: 10px;}
	.aboutbox .subtitle{font-size: 16px; line-height: 24px; margin-bottom: 10px;}
	.aboutbox .box-three-ikk{margin-top: 20px;}
	.aboutbox .box-three-ikk .list{width: 220px; margin: 0 auto 15px; float: none; height: 50px;}
	.aboutbox .box-three-ikk .list:last-child{margin-right: auto;}
	.aboutbox .box-three-ikk .list .f-lg{font-size: 50px;}
	.aboutbox .box-three-ikk .list .f-right{font-size: 20px;}
	.aboutbox .about-quotes{margin-top: 30px;}
	.aboutbox.venuegallery .btn-inqury{right: 20px;}
	.aboutbox.venuegallery h1{margin-bottom: 55px;}
	
	.about-quotes .text-quotes{margin-top: 20px; font-size: 18px; line-height: 24px;}
	.about-quotes .auth-text{margin-top: 20px;}

	.about-story{padding-bottom: 20px;}
	.about-story .flw-right{display: none;}
	.about-story .box-slide-story{display: none;}
	.about-story .box-slider-story-desc{width: 100%; min-height: 0;}
	.about-story .box-slider-story-desc .navstoryres{display: block;}
	.about-story .box-slider-story-desc .navstoryres select{border: 1px solid #e1e1e1; background: #f6f6f6; width: 100%; height: 36px; padding: 0 40px 0 15px; font-size: 14px; font-family: "Trirong"; font-weight: 700; 
		color: $pink; background: url('../images/material/bg-combobox-pink.png') no-repeat center right;}
	.about-story .box-slider-story-desc .list{padding-left: 0;}
	.about-story .box-slider-story-desc .desc{padding-bottom: 20px;}
	.about-story .box-slider-story-desc .desc .title-story{position: relative; top: auto; left: auto; text-align: left; margin-bottom: 10px;}
	.about-story .box-slider-story-desc .desc .title-story h1{line-height: 30px;}
	.about-story .box-slider-story-desc .desc .title-story h3{line-height: 28px;}

	.philosophy{padding-bottom: 20px;}
	.philosophy .quotes-philosophy h1{font-size: 20px; letter-spacing: 5px; margin-bottom: 10px;}
	.philosophy .quotes-philosophy h2{font-size: 18px;}
	.philosophy .quotes-philosophy span{margin-bottom: 20px;}

	.our-team{padding-bottom: 50px;}
	.our-team .flw-left{display: none;}
	.our-team .fig-team{margin-bottom: 30px;}
	.our-team .title-content h4{font-size: 16px; line-height: 24px; margin-bottom: 10px;}
	.our-team p.filsof{margin-bottom: 40px; width: 100%;}

	.box-service .line-v{display: none;}
	.box-service .list-service{margin-bottom: 30px;}
	.box-service .list-service .service-desc{position: relative; top: auto; left: auto; @include transform(translateY(0)); width: 100%; text-align: left; padding: 0 0 30px;}
	.box-service .list-service .service-desc h1{font-size: 40px; line-height: 40px;}
	.box-service .list-service .service-desc h3{font-size: 25px; line-height: 25px; margin-bottom: 10px;}
	.box-service .list-service .service-desc .service-text{width: 100%; float: none;}
	.box-service .list-service .service-img{padding-left: 0; width: 100%; text-align: center;}
	.box-service .list-service .service-img figure{float: none;}
	.box-service .list-service .service-img figure img{max-width: 300px;}
	.box-service .list-service:nth-child(odd) .service-desc{padding-left: 0;}
	.box-service .list-service:nth-child(odd) .service-desc .service-text{float: none;}
	.box-service .list-service:nth-child(odd) .service-img figure{float: none;}

	/*.box-exvenue .list-venue{width: 100%; margin-right: 0; margin-bottom: 30px;}*/
	.box-exvenue .list-venue figure img{max-width: 100%;}
	.box-exvenue .list-venue .box-desc{top: 20px; left: 20px; right: 20px; bottom: 20px;}
	.box-exvenue .list-venue .box-desc .desc h4{font-size: 22px; line-height: 26px;}

	.explore-venue{padding-bottom: 50px;}

	.coming-soon .left{padding-left: 20px; padding-right: 20px;}
	.coming-soon .left .logo img{width: 75px;}
	.coming-soon .left .content-cs{margin-top: 30px;}
	.coming-soon .left .content-cs h1{font-size: 22px; margin-bottom: 20px;}
	.coming-soon .left .content-cs h5{font-size: 16px; line-height: 24px; margin-bottom: 10px;}
	.coming-soon .right{padding: 0;}
	.coming-soon .right .slidercs .list-slide .desc{padding-left: 30px; padding-right: 30px; top: -35px;}
	.coming-soon .right .slidercs .list-slide .desc h1{font-size: 22px; line-height: 24px; margin-bottom: 10px;}
	.coming-soon .right .slidercs .list-slide .desc h1 .line-v{height: 70px; width: 3px; left: -10px;}
	.coming-soon .box-partner-cs .list-partner{width: 33.33333333%;}

	.desc-detvenue{font-size: 14px; line-height: 22px;}
	.box-detail-venue .left{width: 100%; margin-bottom: 20px;}
	.box-detail-venue .right{width: 100%;}
	.box-detail-venue .right h4{font-size: 14px; margin-bottom: 5px;}
	.box-detail-venue .right span{font-size: 13px;}
	.box-partner .list-partner{width: 33.333333333%;}

	.detailsection{padding-bottom: 60px;}

	.box-gallery-detail .wrapper{padding: 0;}
	.box-gallery-detail .box-slide-gallery{padding: 0 20px;}
	.box-gallery-detail .box-slide-gallery .inner-slide-gallery{padding: 0 20px;}
	.box-gallery-detail .box-slide-gallery .slick-prev, .box-gallery-detail .box-slide-gallery .slick-next{width: 10px; height: 16px; background-size: cover;}
	.box-gallery-detail .box-slide-gallery .slick-prev{left: -20px}
	.box-gallery-detail .box-slide-gallery .slick-next{right: -20px}
	.box-gallery-detail hr{display: none;}

	.venue-location{margin-top: 0;}
	.venue-location .ven-left .mapscontact{height: 233px;}
	.venue-location .ven-right{top: -33px; padding: 20px 30px; font-size: 14px; line-height: 22px;}
	.venue-location .ven-right h2{line-height: 26px; margin-bottom: 10px;}
	.venue-location .ven-right h2 .line-v{width: 3px; left: -10px; height: 80px;}
	.venue-location .ven-right h4{font-size: 14px; line-height: 22px; margin-bottom: 0;}
	.venue-location .ven-right p{line-height: 22px;}

	.box-gallery .list-gallery .thumb-vids{width: 35px; height: 35px; padding-top: 11px;}

	.popup-gallery .box-thumb-slider{padding: 0px;}
	.popup-gallery .box-thumb-slider .caption-slide{font-size: 14px;}
	.popup-gallery .img-large .list-slide figure{width: auto;}
	/*.popup-gallery .img-large .list-slide figure img{position: relative; left: 50%; @include transform(translateX(-50%)); height: 100vh; min-height: 300px; width: auto;}*/
	.popup-gallery .img-large .list-slide figure img{position: relative; height: auto; min-height: 300px; width: 100%; max-width: 100%;}
	.popup-gallery .close-pop-gallery{top: 0; padding: 0 15px; font-size: 12px; height: 40px; line-height: 40px;}
	.popup-gallery .close-pop-gallery .ic-close{position: relative; top: 3px; margin-left: 10px;}

	.box-filter{
		border: none;
		.list-filter-gallery{
			margin-bottom: 10px; border: none;
			&:nth-child(2n){float: right;}
			&.svpartner, &.svenue, &.swtheme, &.sphoto{width: 49%;}
			&.search-filter{
				padding-top: 0; width: 100%;
				.btn-oval{width: 100%;}
			}
		}
		select{background-position: 100% 50%; height: 30px; border: 1px solid #e6e6e6; padding-right: 38px}
	}

	.faq-content .btn-inqury{right: 20px;}

	.box-faq{font-size: 14px;}
	.box-faq .list-faq{padding: 15px 0;}
	.box-faq .list-faq .qst{font-size: 14px; line-height: 22px;}
	.box-faq .list-faq.active .qst{font-size: 14px; line-height: 22px;}
	.box-faq .list-faq .answr{padding-top: 8px;}

	.box-form input[type="text"], .box-form input[type="email"], .box-form textarea{font-size: 18px;}
	.box-form .captcha{width: 100%;}
	.box-form .btnform{width: 100%;}
	.box-form .btnform .btn-oval{width: 100%;}
	.box-form .lfaq{font-size: 14px; line-height: 22px;}

	.box-follow-contact{margin-top: 40px; margin-bottom: 30px;}
	.box-follow-contact .box-sosmed-lg a{width: 18%;}

	.sec-address .wrapper{padding: 0;}

	.box-call{font-size: 13px; line-height: 18px;}

	.banner-thankyou .desc h1{font-size: 50px;}
	.banner-thankyou .desc h5{font-size: 14px; line-height: 20px;}

	.box-contact-right .inner{width: 100%;}
	.box-contact-right .inner h3{font-size: 22px;}
	.box-contact-right .inner .line-v{height: 60px;}

	.other-venue-section{padding-bottom: 60px;}


	footer{font-size: 10px; padding: 10px 20px; min-height: auto; line-height: 16px; }
}

@media(min-width: 1px) and (max-width: 490px){
	.box-exvenue{text-align: center;}
	.box-exvenue .list-venue{width: 320px; max-width: 100%; margin: 0 0 30px; float: none; display: inline-block;}
	.box-exvenue .list-venue .box-desc{text-align: left;}
	/*.box-exvenue .list-venue figure{height: 280px;}*/

	.box-gallery .list-gallery{width: 100%; margin-right: 0;}

	.popup-gallery .box-thumb-slider .thumb-slider{display: none;}
	.popup-gallery .img-large .list-slide .caps-large{font-size: 16px;}

	.ui-datepicker table{font-size: 12px;}
	.ui-widget{font-size: 14px;}

	.box-wa {
		right: 20px;
		a {
			font-size: 0; padding: 5px;
			i {top: -5px;left: 5px; width: 20px !important; height: 20px !important;}
		}
	}
}

@media(min-width: 1px) and (max-width: 420px){
	.box-form .captcha{
		.g-recaptcha{@include transform(scale(0.7)); transform-origin: 0 0; -webkit-transform-origin: 0 0; -moz-transform-origin: 0 0; -o-transform-origin: 0 0;}
	}
}
@media(min-width: 1px) and (max-width: 380px){
	h1{font-size: 22px;}
	h2{font-size: 18px;}

	.border-white{top: 50px;}
	.title-content span{font-size: 11px;}

	header{min-height: 50px;}
	header .logo img, header .logo-float img{width: 70px;}
	header .btn-burg-menu{top: 16px;}
	header .btn-burg-menu span:nth-child(2){top: 8px;}
	header .btn-burg-menu span:last-child{top: 16px;}

	.box-inquiry{bottom: 15px; right: 15px;}

	.slidehome .list-slide .desc{font-size: 16px; line-height: 18px;}
	.slidehome .list-slide .desc .logo-lg img{width: 150px;}

	.sec-content .desc-sechome h2{line-height: 20px;}

	.sec-aboutus > figure{left: 17%;}

	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding .title-thumb-wedding{margin: 10px 0 20px;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding p{line-height: 22px;}

	.sec-wedding-service .content-wedding .box-weddingserviceone{margin-top: 0; padding: 20px 0;}
	.sec-wedding-service .content-wedding .box-weddingserviceone .right{padding-left: 30px;}
	.sec-wedding-service .content-wedding .box-weddingserviceone .title-thumb-wedding{margin-bottom: 15px;}
	.sec-wedding-service .content-wedding .box-weddingserviceone .title-thumb-wedding h2{font-size: 22px;}

	.sec-ourvenue .inner-ourvenue .desc-venue p{line-height: 22px;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor{width: 49%; margin-right: 2%;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor:nth-child(3n){margin-right: 2%;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor:nth-child(3n + 2){margin-right: 2%;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor:nth-child(2n){margin-right: 0;}

	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome .box-sosmed-lg a{width: 22.5%;}

	.about-quotes .text-quotes{font-size: 14px; line-height: 22px;}

	.about-story .box-slider-story-desc .desc p{font-size: 14px; line-height: 22px;}

	.philosophy .quotes-philosophy h1{letter-spacing: 1px;}
	.philosophy .quotes-philosophy h2{font-size: 17px;}
	.philosophy .box-two.visi{margin-top: 40px;}

	.box-service{margin-top: 30px;}
	.box-service .list-service .service-img figure img{max-width: 100%;}

	.box-filter select{background-position: 113% 50%; padding-right: 30px;}

	.coming-soon .box-partner-cs .list-partner{width: 50%;}

	.box-gallery-detail .box-slide-gallery .slick-dots li button{height: 2px; width: 22px;}

	.box-partner .list-partner{width: 50%;}

	.box-follow-contact .box-sosmed-lg a{width: 24%; vertical-align: top;}

	.banner-thankyou figure{min-height: 300px;}
	.banner-thankyou figure img{height: 100%; max-width: none; position: relative; left: 50%; @include transform(translateX(-50%));}
	.banner-thankyou .desc{width: 210px; top: 75%;}
	.banner-thankyou .desc h1{font-size: 30px; margin-bottom: 20px;}
	.banner-thankyou .desc h5{font-size: 14px; line-height: 22px;}
	.banner-thankyou .desc .btn-oval{font-size: 12px;}

	.box-wa {bottom: 15px;}
}